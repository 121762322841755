const ADDRESS_TYPES = "addressTypes";
const AUTO_CONSOLIDATE_PAYEES = "autoConsolidatePayees";
const CHECK_COVER_LETTERS = "checkCoverLetters";
const ESCROW_OFFICERS = "escrowOfficers";
const ESCROW_PROCESSORS = "escrowProcessors";
const ESCROW_ACCOUNTS = "escrowAccounts";
const USER_ESCROW_ACCOUNTS = "userEscrowAccounts";
const EXPORT_FORMATS = "exportFormats";
const FILE_NUMBER_POOLS = "fileNumberPools";
const TITLE_OFFICERS = "titleOfficers";
const TITLE_PROCESSORS = "titleProcessors";
const SALES_REPS = "salesReps";
const CLOSING_AGENTS = "closingAgents";
const NOTARIES = "notaries";
const STATUSES = "statuses";
const STAFF = "staff";
const USED_STATUSES = "usedStatuses";
const WORKFLOW_PROCESS_TEMPLATES = "workflowProcessTemplates";
const TITLE_COMPANIES = "titleCompanies";
const ESCROW_UNITS = "escrowUnits";
const TITLE_UNITS = "titleUnits";
const ORDER_CATEGORIES = "orderCategories";
const UNDERWRITERS = "underwriters";
//const UNDERWRITERWITHNUMBERRANGES = "underwriters_with_numberranges";
const POLICYNUMBERRANGES = "policynumberranges";
const SOURCE_OF_BUSINESSES = "sourceOfBusinesses";
const LOCATIONS = "locations";
const ORDER_SERVICES = "orderServices";
const BUYER_SELLER_CONTACTS = "buyerSellerContacts";
const BUYER_SELLER_RELATIONSHIP_TYPES = "buyerSellerRelationshipTypes";
const VESTING_INFORMATION_TYPES = "vestingInformationTypes";
const STATES = "states";
const COUNTIES = "counties";
const ROLES = "roles";
const REGIONS = "regions";
const BRANCHES = "branches";
const TYPE_FUNDS = "typeFunds";
const PAPERLESS_CLOSER_LAYOUTS = "paperlessCloserLayouts";
const LIST_CODES = "listCodes";
const BI_ROLES = "biRoles";
const CLOSING_TYPES = "closingTypes";
const MORTGAGE_TYPES = "mortgageTypes";
const TRANSACTION_TYPES = "transactionTypes";
const POLICY_FORM_TYPES = "policyFormTypes";
const RECORDING_TYPES = "recordingTypes";
const RECORDING_STATUSES = "recordingStatuses";
const SIGNING_STATUSES = "signingStatuses";
const MORTGAGE_CLAUSE_TYPES = "mortgageClauseTypes";
const ORDER_TIME_TRACKING_CATEGORIES = "orderTimeTrackingCategories";
const ORDER_TIME_TRACKING_ACCOUNT_CODES = "orderTimeTrackingAccountCodes";
const INVOICE_TYPE_FUNDS = "invoiceTypeFunds"


//property lookups
const PROPERTY_TYPES = "propertyTypes";
const PROPERTY_LAYOUTS = "propertyLayouts";
const SUBDIVISIONS = "subdivisions";
const SURVEYS = "surveys";

//proration lookups
const PAYOFF_DESCRIPTIONS = "payoffDescriptions";
const PRORATION_TYPES = "prorationTypes";
const PRORATION_METHODS = "prorationMethods";
const PRORATION_PAYMENT_DUE_TYPES = "prorationPaymentDueTypes";
const PRORATION_BILLING_PERIODS = "prorationBillingPeriods";
const PRORATION_DEFAULTS = "prorationDefaults";

//Documents
const DOCUMENT_CATEGORIES = "documentCategories";
const DOCUMENT_TAGS = "documentTags";
const DOCUMENT_SOURCES = "documentTemplateSources";
const FILE_SCAN_CATEGORIES = "fileScanCategories";
const FILE_SCAN_DESCRIPTIONS = "fileScanDescriptions";
const FILE_TYPES = "fileTypes";
const RECORDING_REJECTION_REASONS = "recordingRejectionReasons"

const DURATIONS = "durations";
const STANDARD_LANGUAGE_CATEGORIES = "standardLanguageCategories";
const STANDARD_LANGUAGE_SECTIONS = "standardLanguageSections";

//workflow
const WORKFLOW_DEPARTMENTS = "workflowDepartments";
const WORKFLOW_TASKS = "workflowTasks";
const WORKFLOW_PREDEFINED_TASKS = "workflowPredefinedTasks";
const WORKFLOW_TASK_PACKAGES = 'workflowTaskPackages'
const WORKFLOW_TASK_PACKAGE_CATEGORIES = 'workflowTaskPackageCategories'

//settlements
const ACCOUNTING_CODES = "accountingCodes";
const REVENUE_ACCOUNTING_CODES = "revenueAccountingCodes";
const SETTLEMENT_FEE_LINE_TYPES = "settlementFeeLineTypes";
const NET_FUND = "netFunds";
const PREMIUMS = "premiums";
const RECORDING_DOCUMENTS = "recordingDocuments";
const RECORDING_DOCUMENT_TYPES = "recordingDocumentTypes";

const DUPLICATE_ORDER_SEARCH_SETUP = "duplicateOrderSearchSetup";

const LOAN_PURPOSE = "loanPurposes";
const LOAN_PRODUCT = "loanProducts";
const LOAN_TYPE = "loanTypes";
const HUD_LOAN_TYPE = "hudLoanTypes";
const SETTLEMENT_TYPES = "settlementTypes";
const CUSTOMERS = "customers";
const BUYERS_SELLERS = "buyersSellers";
const COMPANY_CONTACTS = "companyContacts";

//communication
const NOTE_CATEGORIES = "noteCategories";

export default {
    ACCOUNTING_CODES,
    REVENUE_ACCOUNTING_CODES,
    ADDRESS_TYPES,
    AUTO_CONSOLIDATE_PAYEES,
    BRANCHES,
    BUYERS_SELLERS,
    BUYER_SELLER_CONTACTS,
    BUYER_SELLER_RELATIONSHIP_TYPES,
    CHECK_COVER_LETTERS,
    CLOSING_AGENTS,
    CLOSING_TYPES,
    COMPANY_CONTACTS,
    COUNTIES,
    CUSTOMERS,
    DOCUMENT_CATEGORIES,
    DOCUMENT_TAGS,
    DOCUMENT_SOURCES,
    DURATIONS,
    DUPLICATE_ORDER_SEARCH_SETUP,
    ESCROW_ACCOUNTS,
    ESCROW_OFFICERS,
    ESCROW_PROCESSORS,
    ESCROW_UNITS,
    EXPORT_FORMATS,
    FILE_NUMBER_POOLS,
    FILE_SCAN_CATEGORIES,
    FILE_SCAN_DESCRIPTIONS,
    FILE_TYPES,
    LOAN_PRODUCT,
    LOAN_PURPOSE,
    LOAN_TYPE,
    HUD_LOAN_TYPE,
    LOCATIONS,
    MORTGAGE_TYPES,
    NET_FUND,
    NOTARIES,
    ORDER_CATEGORIES,
    ORDER_SERVICES,
    PAYOFF_DESCRIPTIONS,
    POLICY_FORM_TYPES,
    PROPERTY_LAYOUTS,
    PROPERTY_TYPES,
    PRORATION_BILLING_PERIODS,
    PRORATION_DEFAULTS,
    PRORATION_METHODS,
    PRORATION_PAYMENT_DUE_TYPES,
    PRORATION_TYPES,
    RECORDING_DOCUMENTS,
    RECORDING_DOCUMENT_TYPES,
    RECORDING_REJECTION_REASONS,
    RECORDING_TYPES,
    REGIONS,
    ROLES,
    SALES_REPS,
    SETTLEMENT_FEE_LINE_TYPES,
    SETTLEMENT_TYPES,
    SIGNING_STATUSES,
    SOURCE_OF_BUSINESSES,
    STAFF,
    STANDARD_LANGUAGE_CATEGORIES,
    STANDARD_LANGUAGE_SECTIONS,
    STATES,
    STATUSES,
    SUBDIVISIONS,
    SURVEYS,
    TITLE_COMPANIES,
    TITLE_OFFICERS,
    TITLE_PROCESSORS,
    TITLE_UNITS,
    TRANSACTION_TYPES,
    TYPE_FUNDS,
    UNDERWRITERS,
    USER_ESCROW_ACCOUNTS,
    // UNDERWRITERWITHNUMBERRANGES,
    POLICYNUMBERRANGES,
    USED_STATUSES,
    VESTING_INFORMATION_TYPES,
    WORKFLOW_DEPARTMENTS,
    WORKFLOW_TASKS,
    WORKFLOW_TASK_PACKAGES,
    WORKFLOW_TASK_PACKAGE_CATEGORIES,
    WORKFLOW_PREDEFINED_TASKS,
    WORKFLOW_PROCESS_TEMPLATES,
    PAPERLESS_CLOSER_LAYOUTS,
    LIST_CODES,
    PREMIUMS,
    BI_ROLES,
    RECORDING_STATUSES,
    MORTGAGE_CLAUSE_TYPES,
    ORDER_TIME_TRACKING_CATEGORIES,
    ORDER_TIME_TRACKING_ACCOUNT_CODES,
    NOTE_CATEGORIES,
    INVOICE_TYPE_FUNDS
};